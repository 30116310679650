import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "modal bg_grey d-flex justify-content-between align-items-center flex-column" }
const _hoisted_2 = { class: "page_padding text-center" }
const _hoisted_3 = { class: "white text-uppercase text-center fw_700" }
const _hoisted_4 = { class: "page_padding w-100 mt-4" }
const _hoisted_5 = { class: "page_padding d-flex w-100" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_localized_text = _resolveComponent("localized-text")!
  const _component_form_field = _resolveComponent("form-field")!

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", _hoisted_2, [
      _createVNode(_component_localized_text, {
        type: "h3",
        class: "white text-uppercase text-center fw_700",
        localizedKey: "backoffice_ModificaVocabolo",
        text: "Modifica Vocabolo"
      }),
      _createVNode("span", _hoisted_3, _toDisplayString(_ctx.localModel.key), 1)
    ]),
    _createVNode("div", _hoisted_4, [
      (_ctx.localModel)
        ? (_openBlock(), _createBlock(_component_form_field, {
            key: 0,
            type: "tiny-mce-view",
            modelValue: _ctx.localModel,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.localModel = $event))
          }, null, 8, ["modelValue"]))
        : _createCommentVNode("", true)
    ]),
    _createVNode("div", _hoisted_5, [
      _createVNode("button", {
        class: "secondaryButton w-50 me-1",
        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('close')))
      }, [
        _createVNode(_component_localized_text, {
          localizedKey: "backoffice_Annulla",
          text: "Annulla"
        })
      ]),
      _createVNode("button", {
        class: "primaryButton w-50 ms-1",
        onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.save()))
      }, [
        _createVNode(_component_localized_text, {
          localizedKey: "backoffice_Salva",
          text: "Salva"
        })
      ])
    ])
  ]))
}